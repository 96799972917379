import { Box, Flex } from '@energiebespaarders/symbols';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import React from 'react';
import styled from 'styled-components';
import type { getAllTags_allBlogTags } from '~/types/generated/cms/getAllTags';
import HybridLink from '../HybridLink';

export const StyledTag = styled(Box)<{ $bgColor?: string; $color?: string; $fontSize?: number }>`
  text-align: center;
  background: ${x => themify(x.$bgColor || 'greenLighter')};
  font-weight: 500;
  color: ${x => themify(x.$color || 'white')};
  border-radius: 100px;
  font-size: ${x => x.theme.type.scale[x.$fontSize ?? 7]};
  font-weight: 500;
  line-height: 1;
  transition: all 0.2s;
  width: 100%;
  white-space: nowrap;

  &:hover {
    background: ${x => x.theme.colors.green};
  }
`;

interface Props {
  tags: readonly getAllTags_allBlogTags[];
  bgColor?: string;
  color?: string;
  onlyShowHighlighted?: boolean;
}

const TagCloud: React.FC<Props> = ({ tags, onlyShowHighlighted = false }) => {
  const renderedTags: getAllTags_allBlogTags[] = [
    ...(onlyShowHighlighted ? tags.filter(t => t.isHighlighted).slice(0, 5) : tags),
  ].sort((a, b) => (a.title || '').localeCompare(b.title || ''));

  return (
    <Flex flexWrap="wrap" mx="-2px" justifyContent={onlyShowHighlighted ? 'center' : 'flex-start'}>
      {renderedTags.map(tag => (
        <Box key={tag.id} p="2px">
          <HybridLink to={`/blog/tag/${tag.slug}`} prefetch={false}>
            <StyledTag
              p={1}
              pt="3px"
              style={{ whiteSpace: 'nowrap', minHeight: 'unset' }}
              $bgColor={onlyShowHighlighted ? 'greenDark' : ''}
              $fontSize={onlyShowHighlighted ? 6 : 7}
            >
              {tag.title?.toLowerCase()}
            </StyledTag>
          </HybridLink>
        </Box>
      ))}
    </Flex>
  );
};

export default TagCloud;
