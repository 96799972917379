import { Box, Card, Flex, theme } from '@energiebespaarders/symbols';
import { Color, Smaller } from '@energiebespaarders/symbols/helpers';
import { padding, themify } from '@energiebespaarders/symbols/styles/mixins';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import React from 'react';
import { Image } from 'react-datocms';
import styled, { css } from 'styled-components';
import HybridLink from '~/components/HybridLink';
import { dateStringFormatters } from '~/utils/constants';
import TagCloud, { StyledTag } from './TagCloud';
import type { BlogPost } from './BlogContent';
import type { MouseEvent } from 'react';

export const StyledPostImage = styled(Image)`
  width: 100%;
  transition: transform 0.15s ${theme.curves.sharp};
  border-radius: 6px 6px 0 0;
  height: 100%;

  picture img {
    object-fit: cover;
  }
`;

interface ImageZoomWrapperProps {
  $height: string;
  $overlayOpacity?: number;
  $isActive?: boolean;
}

export const ImageZoomWrapper = styled(Box)<ImageZoomWrapperProps>`
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 6px 6px 0 0;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
    opacity: ${x => x.$overlayOpacity ?? 1};
    transition: opacity 0.15s ${x => x.theme.curves.sharp};
  }

  ${StyledPostImage} {
    height: ${x => x.$height};

    ${x =>
      x.$isActive !== undefined &&
      css`
        filter: ${x.$isActive ? 'none' : 'grayscale(100%)'};
      `};
  }
`;

export const StyledPostCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
  transform: translateY(0);
  transition: transform 0.15s ${theme.curves.sharp};
  cursor: pointer;

  &:hover {
    transform: translateY(-3px);

    ${StyledPostImage} {
      transform: scale(1.025);
    }

    ${ImageZoomWrapper} {
      &::after {
        background: none;
        opacity: 0;
      }
    }
  }
`;

const StylePostTitle = styled.h3`
  font-size: ${x => x.theme.type.scale[4]};
  line-height: 1.15;
`;

const ClampedExcerpt = styled.div`
  ${padding(3, 'x')}
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: ${x => x.theme.type.scale[6]};
`;

export const Badges = styled(Flex)`
  position: absolute;
  top: 9px;
  right: 6px;
  z-index: 2;
`;

export const Badge = styled(StyledTag)<{ $bgColor: string }>`
  background: ${x => themify(x.$bgColor)};
  pointer-events: none;
  font-weight: 600;
`;

interface PostPreviewProps {
  post: BlogPost;
}

const PostPreview: React.FC<PostPreviewProps> = ({ post }) => {
  const router = useRouter();
  const postLink = `/blog/${post.slug}`;

  return (
    <StyledPostCard p={0} animation="none" borderRadius="6px" onClick={() => router.push(postLink)}>
      <HybridLink to={postLink} prefetch={false}>
        <Badges title={post.title} />
        <ImageZoomWrapper $height="210px">
          {/* <StyledPostImage alt={'Post Image'} src={post.image?.responsiveImage?.src} /> */}
          {/* {post.image?.responsiveImage?.webpSrcSet && (
            <StyledPostImage data={post.image!.responsiveImage!} />
          )} */}

          <StyledPostImage data={post.image!.responsiveImage!} />
        </ImageZoomWrapper>
      </HybridLink>
      <Box pt={3} px={3}>
        <HybridLink to={postLink} style={{ color: theme.colors.grayBlack }} prefetch={false}>
          <StylePostTitle title={post.title || ''}>{post.title}</StylePostTitle>
        </HybridLink>
      </Box>

      <ClampedExcerpt>
        <p>{post.description}</p>
      </ClampedExcerpt>

      <Box pt={3} px={3} mt={'auto'}>
        <Smaller>
          <div>
            <Color c="grayDark">Geplaatst op</Color>{' '}
            {dayjs(post.firstPublishedAt || post._publishedAt).format(dateStringFormatters.short)}{' '}
            <Color c="grayDark">in</Color>{' '}
            <>
              <span onClick={(e: MouseEvent) => e.stopPropagation()}>
                <HybridLink to={`/blog/category/${post.category!.slug}`}>
                  {post.category?.name}
                </HybridLink>
              </span>
            </>
          </div>
        </Smaller>
      </Box>

      <Box p={3} pt={1} style={{ lineHeight: 1 }}>
        {post.tags.length > 0 ? (
          <TagCloud tags={post.tags} />
        ) : (
          <Smaller>
            <Color c="gray">Geen tags</Color>
          </Smaller>
        )}
      </Box>
    </StyledPostCard>
  );
};

export default PostPreview;
