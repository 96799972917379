import { Box, Card, Flex, theme } from '@energiebespaarders/symbols';
import { Color, Smaller } from '@energiebespaarders/symbols/helpers';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';
import HybridLink from '~/components/HybridLink';
import { dateStringFormatters } from '~/utils/constants';
import { ImageZoomWrapper, StyledPostImage } from './PostPreview';
import TagCloud, { StyledTag } from './TagCloud';
import type { BlogPost } from './BlogContent';
import type { MouseEvent } from 'react';

const StyledPostCard = styled(Card)`
  width: 100%;
  cursor: pointer;

  &:hover {
    ${StyledPostImage} {
      transform: scale(1.025);
    }

    ${ImageZoomWrapper} {
      &::after {
        background: none;
        opacity: 0;
      }
    }
  }
`;

const StylePostTitle = styled.h3`
  font-size: ${x => x.theme.type.scale[2]};
  line-height: 1.15;
`;

const ClampedExcerpt = styled.div`
  /* NOTE: This clamps the excerpt to 3 lines
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden; */
  flex-grow: 1;
  font-size: ${x => x.theme.type.scale[6]};
`;

export const Badges = styled(Flex)`
  position: absolute;
  top: 9px;
  right: 6px;
  z-index: 2;
`;

export const Badge = styled(StyledTag)<{ $bgColor: string }>`
  background: ${x => themify(x.$bgColor)};
  pointer-events: none;
  font-weight: 600;
`;

interface PostSpotlightProps {
  post: BlogPost;
}

const PostSpotlight: React.FC<PostSpotlightProps> = ({ post }) => {
  const router = useRouter();
  const postLink = `/blog/${post.slug}`;
  const isMobile = useIsMobile();

  return (
    <StyledPostCard p={0} animation="none" borderRadius="6px" onClick={() => router.push(postLink)}>
      <Flex flexWrap="wrap">
        <Box width={[1, 1, 1, 1 / 2, 2 / 5]}>
          <HybridLink to={postLink}>
            <ImageZoomWrapper
              $height={isMobile ? '180px' : '100%'}
              style={isMobile ? {} : { borderRadius: '6px 0 0 6px', height: '100%' }}
              title={post.title || ''}
            >
              <StyledPostImage data={post.image!.responsiveImage!} />
            </ImageZoomWrapper>
          </HybridLink>
        </Box>
        <Box width={[1, 1, 1, 1 / 2, 3 / 5]} p={3}>
          <Flex flexDirection="column" width="100%" height="100%">
            <HybridLink to={postLink} style={{ color: theme.colors.grayBlack }}>
              <StylePostTitle title={post.title || ''}>{post.title}</StylePostTitle>
            </HybridLink>
            <ClampedExcerpt dangerouslySetInnerHTML={{ __html: post.description || '' }} />
            <br />
            <Smaller>
              <div>
                <Color c="grayDark">Geplaatst op</Color>{' '}
                {dayjs(post.firstPublishedAt || post._publishedAt).format(
                  dateStringFormatters.short,
                )}{' '}
                <Color c="grayDark">in</Color>{' '}
                <>
                  <span onClick={(e: MouseEvent) => e.stopPropagation()}>
                    <HybridLink to={`/blog/category/${post.category?.slug}`}>
                      {post.category?.name}
                    </HybridLink>
                  </span>
                </>
              </div>
            </Smaller>
            <Box pt={2} style={{ lineHeight: 1 }}>
              {post.tags.length > 0 ? <TagCloud tags={post.tags} /> : <></>}
            </Box>
          </Flex>
        </Box>
      </Flex>
    </StyledPostCard>
  );
};

export default PostSpotlight;
