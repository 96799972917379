import { Box, Card, Flex, Icon } from '@energiebespaarders/symbols';
import { Book, Larger } from '@energiebespaarders/symbols/helpers';
import { CaretRight } from '@energiebespaarders/symbols/icons/line';
import React from 'react';
import styled from 'styled-components';
import HybridLink from '~/components/HybridLink';
import type { getAllBlogCategories_allBlogCategories } from '~/types/generated/cms/getAllBlogCategories';
import type { getAllTags_allBlogTags } from '~/types/generated/cms/getAllTags';
import TagCloud from './TagCloud';

const StyledIcon = styled(Icon)`
  transition: transform 0.15s;
`;

const StyledLink = styled(HybridLink)`
  display: inline-block;
  color: white;

  &:hover {
    text-decoration: underline;
    ${StyledIcon} {
      transform: translateX(6px);
    }
  }
`;

interface Props {
  tags: readonly getAllTags_allBlogTags[];
  categories: readonly getAllBlogCategories_allBlogCategories[];
}

const CategoryNav: React.FC<Props> = ({ tags, categories }) => {
  const renderedCategories =
    categories.filter(c => c.isHighlighted).length >= 3
      ? categories.filter(c => c.isHighlighted)
      : categories;

  return (
    <Card p={3} bgColor="greenDarker" color="white" borderRadius="6px">
      <Flex flexWrap="wrap" mx={-1}>
        <Box width={1} px={1}>
          <Larger>
            <Book>
              <p>Kom meer te weten over</p>
            </Book>
          </Larger>
        </Box>
        {renderedCategories.slice(0, 3).map(category => (
          <Box key={category.id} width={1} px={1} py={1}>
            <StyledLink
              to={`/blog/category/${category.slug}`}
              style={{ color: 'white' }}
              prefetch={false}
            >
              <StyledIcon icon={CaretRight} stroke="white" mr={2} width="1.25em" />
              <Book>{category.name}</Book>
            </StyledLink>
          </Box>
        ))}
        <Box width={1} px={1} pt={2}>
          <p>
            <Book>Of bekijk artikelen per tag</Book>
          </p>
          <TagCloud tags={tags} onlyShowHighlighted />
        </Box>
      </Flex>
    </Card>
  );
};

export default CategoryNav;
